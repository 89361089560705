import axios from "axios";
import { message } from "antd";
import cookie from "react-cookies";

const instance = axios.create({
  baseURL: "/api",
  headers: { "Content-Type": "application/json" },
});

async function verifyToken() {
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    window.location.href = "/user-login";
  }

  try {
    const res = await axios.post("/api/auth/verify/", { token: accessToken });
    return res.data.data.valid;
  } catch (error) {
    throw error;
  }
}

async function refreshToken() {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    window.location.href = "/user-login";
  }

  try {
    const res = await axios.post("/auth/refresh/", {
      refresh_token: refreshToken,
    });

    if (res.status !== 200) {
      throw new Error("Failed to refresh token");
    }

    localStorage.setItem("accessToken", res.data.data.access_token);
    localStorage.setItem("refreshToken", res.data.data.refresh_token);
  } catch (error) {
    throw error;
  }
}

instance.interceptors.request.use(
  async (config) => {
    try {
      const valid = await verifyToken();
      if (!valid) {
        await refreshToken();
      }
    } catch (error) {
      await message.error("登陆过期，请重新登录", 3, () => {
        cookie.remove("user", { path: "/" });
        window.location.href = "/user-login";
      });
      return Promise.reject(error);
    }

    const accessToken = localStorage.getItem("accessToken");
    // console.log("in config", accessToken);
    config.headers.Authorization = accessToken;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => res,
  async (error) => {
    // console.error("request error", error);
    if (error.response.status === 401 || error.response.status === 403) {
      await message.error("登陆过期，请重新登录", 3, () => {
        cookie.remove("user", { path: "/" });
        window.location.href = "/user-login";
      });
      return Promise.reject(error);
    }
    message.error("请求失败，请打开控制台查看内部错误", 3);
    console.error(
      "request error",
      error.response.status,
      error.response.data.message
    );
    return Promise.reject(error);
  }
);

export default instance;
