import axios from 'axios';
import { message } from 'antd';
import cookie from 'react-cookies';


const instance = axios.create({
    baseURL: 'https://e68.wwvas.com',
    headers: { 'Content-Type': 'application/json',
                    "logintype": "web"}
});

instance.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    },
)

instance.interceptors.response.use(
    res => res,
    async error => {
        return Promise.reject(error)
    }
)


export default instance;
